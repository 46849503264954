.Hikes {
  font-family: 'Exo 2', sans-serif;
  overflow-x: hidden;


}
.Hikes h1 {
  font-size: 3rem;
  margin-bottom: 12px;
  font-weight: 800;
  color: #f1f1f1;
  font-weight: 600;
}

.Hikes_Content{
  margin-left: 100px;
  margin-top: 60px;
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  z-index: 100;

}


.Great_Hikes {
  position: relative;
  display: flex;
  align-items: baseline;
  overflow-y: hidden;
  color: #fff;
  gap: 15px;
  border-bottom: 1px solid #fff;
  max-width: min-content;
  white-space: nowrap;
}


.Location {
  max-width: 380px;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 10px  rgba(0, 0, 0, 0.5) ;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.2rem;
  z-index: 100;

font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 1.6px;

}

.Info {
  display: flex;
  flex-direction: column;
  color: #fff;
  /* text-shadow: 2px 2px 10px  rgba(0, 0, 0, 0.8) ; */
  gap: 20px;
}
.Info h2 {
  color: #FFF;
font-size: 48px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.Info p {
  color: #FFF;
font-family: Mukta;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.Carousel {
  margin-top: 60px;
  overflow-x: hidden;
}

@media screen and (max-width: 991px) {

  .Hikes_Content {
    margin-left: 0;
  }
  .Hikes_Content,
  .Carousel{
    position: relative;
    margin-top: 60px;

    padding-left: 120px;
    display: flex;
    flex-direction: column;
    z-index: 100;
    overflow-x: hidden;
  }
  
}

@media screen and (max-width: 768px) {
 

  .Hikes_Content,
  .Carousel{
    position: relative;
    margin-top: 60px;

    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    z-index: 100;
    overflow-x: hidden;
  }
 .Hikes_Content {
  padding-left: 2rem;

 }
 .Carousel {
   padding-left: 1rem;
 }
  
}