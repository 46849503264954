

.HikeDetails {
    position: absolute;
    font-family: 'Exo 2', sans-serif;
    margin-left: 100px;
    z-index: 100;
    padding: 30px 50px;

}

.hikedetails_title {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}


.hikedetails_card {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.20);
    /* backdrop-filter: blur(1px); */
    gap: 10px;
    color: #fff;

    padding: 40px 30px;

  }

  .hikedetails_card  {
  margin-top: 30px;
  margin-bottom: 50px;

}
  .map-control {
    align-items: center;
    max-height: 600px;
    object-fit: contain;
  }


  .country {
    max-width: 210px;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 10px  rgba(0, 0, 0, 0.5) ;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.2rem;
    z-index: 100;
  
    margin-top: 10px;
    margin-bottom: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1.6px;
  
  }

  .d-hikedetails_title--title{
    display: flex;
    align-items: center;
    gap: 15px;

  }

  .btn-actions {
    display: flex;
    width: 50px;
    height: 50px;

  }



  .explore {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 991px) {
    .HikeDetails {
      margin-left: 0;
      padding: 2rem 0.5rem;
    }
    .border-start {
      border: 0px!important;

    }
    .hikedetails_card {
      padding: 20px 10px;
    }
  
  }
  