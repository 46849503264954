.material-symbols-outlined {

  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 20
}
.btn:hover .material-symbols-outlined {

  font-variation-settings:
  'FILL' 1,
  'wght' 300,
  'GRAD' 0,
  'opsz' 20
}

button {
    justify-content: center;
    align-items: center;
    gap: 10px;
}
