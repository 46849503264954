@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&family=Mukta:wght@300;400;500;600&display=swap');


.Home {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Armata', sans-serif;

  color: #f1f1f1;
  background-color: transparent;

  width: 100dvw;
  height: 100dvh;
}

.Home h1 {
  font-size: 3rem;
  margin-bottom: 12px;
  font-weight: 800;
  color: #f1f1f1;
  font-family: 'Exo 2', sans-serif;
  font-weight: 600;
  }

.Glassbox {
  
  width: 420px;

  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.16);
  stroke-width: 2px;
  stroke: rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(3.5px);

  padding: 25px;

}


.Logo_Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Logo_Home img {
  width: 200px;
  margin-bottom: 30px;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}
