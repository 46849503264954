/* Menu.js */
.menu_navbar {
  position: fixed;
  display: flex;
  flex-direction: column;
  max-width: 90px;
  padding: 18px;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .menu_navbar {
    display: none;
  }
  
}

.menu_icons{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 150px;
}
/* UserNav.js */


.User_Nav {
  position: fixed;
  right: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  padding: 25px 30px;
  gap: 30px;
}


/* Background.js */

.background {
  position: fixed;
  width: 100%;
  height: 100dvh;
  background-color: #090909;
  background-position: center;
  background-size: cover;
}

.backgroundImage {
  width: 100%;
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
  /* Set a lower z-index for the overlay */
  box-shadow: inset 20rem 20rem 20rem 10rem #000;
  scroll-margin-top: 0;
  opacity: 0.5;
}

/* Button.js */

.Button {

  display: inline-flex;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #cccccc;
  border: none;
  border-radius: 35px;
  background: #212529;

  cursor: pointer;
}

.Button:Hover {
  background: #424649;

}

.Button .IconButton {
  height: 20px;
}  

.btn-user {
 font-size: 48px;

}


/* You can add more styles as needed */




/* Icons.js */
.icon-menu {
  min-height: 25px;
  min-width: 25px;
  object-fit: contain;
}

.hiking {
  content: url("https://yuricooke.com/mvp/hiking.png");
  max-width: 35px;
  max-height: 35px;
}


.group {
  content: url("https://yuricooke.com/mvp/group.png");
  max-width: 35px;
  max-height: 35px;
}


.favorite {
  content: url("https://yuricooke.com/mvp/favorite.png");
  max-width: 35px;
  max-height: 30px;
}

.return {
  content: url("https://yuricooke.com/mvp/return.png");
  max-width: 25px;
  max-height: 25px;
}



.user {
  content: url("../img/hiking.png");
  max-width: 35px;
  max-height: 35px;
}

/* Hike Card */


.hike_card {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin: 10px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.19);
  background: rgba(0, 0, 0, 0.40);
  box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(3px);
  gap: 10px;
  color: #fff;
  cursor: pointer;
}

.hike_card .hike_img {
  width: 149px;
  height: 98px;
  border-radius: 9px 0px 0px 9px;
}

.hike_card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
}


p {
  margin-top: 0;
  padding-top: 0;

}

